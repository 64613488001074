<template>
  <div v-if="optionList && optionList.length" class="optional">
    <h3 class="optional-tit">{{ title }}</h3>
    <Slide slideUpText="Collapse" slideDownText="Expand all">
      <div class="optional-content">
        <div
          v-for="item in optionList"
          :key="item[valuekey]"
          class="optional-item"
        >
          <Button
            :type="item.checked ? 'primary' : 'default'"
            :disabled="item.disabled"
            @click="$emit('on-click', item)"
          >
            {{ item[labelKey] }}
          </Button>
        </div>
      </div>
      <!-- <template v-if="optionList.length > 3" slot="extra">
        <div class="optional-content">
          <div
              v-for="item in optionList.slice(3)"
              :key="item[valuekey]"
              class="optional-item"
            >
              <Button
                :type="item.checked ? 'primary' : 'default'"
                @click="$emit('on-click', item)"
              >
                {{ item[labelKey] }}
              </Button>
            </div>
        </div>
      </template> -->
    </Slide>
  </div>
</template>
<script>
import { Button } from 'vant'
import Slide from '_c/slide'
export default {
  components: {
    Button,
    Slide
  },
  filters: {
    // 超长省略
    btnfontLeng: value => {
      if (!value) return
      const curlength = 8
      if (value.length > curlength) {
        return value.slice(0, curlength) + '....'
      } else {
        return value
      }
    }
  },
  props: {
    title: String,
    valuekey: String,
    labelKey: String,
    optionList: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style lang="less" scoped>
.optional {
  padding: 0 24px;

  .optional-tit {
    margin-bottom: 30px;
    line-height: 32px;
    font-size: 28px;
    font-weight: 400;
    color: #000;
  }

  .optional-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    .optional-item {
      margin-bottom: 16px;
      height: 60px;
      box-sizing: border-box;

      &:nth-child(3n) {
        margin-right: 0;
      }

      button {
        padding: 0 5px;
        width: 100%;
        height: 100%;
        line-height: 1.2;
        font-size: 28px;
        color: #5F5F6A;
        box-sizing: border-box;
        border: 1px solid #97979D;
        border-radius: 8px;
        background-color: #F0F2F6;
        transition: none;
        overflow: hidden;

        &.van-button--primary {
          font-size: .373333rem;
          color: #fff;
          border-color: #DA3738;
          background-color: #DA3738;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}
</style>
