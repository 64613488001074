<template>
  <div :class="['slide', { 'on': show }]">
    <slot />
    <div class="slide-extra">
      <slot name="extra" />
    </div>
    <div v-if="$slots.extra" class="slide-operator" @click="show = !show">
      <slot name="operate" :show="show">
        <span class="slide-text">{{ slideText }}</span><Icon class="arrow-icon" name="arrow-down"></Icon>
      </slot>
    </div>
  </div>
</template>
<script>
import { Icon } from 'vant'
export default {
  name: 'slide',
  components: {
    Icon
  },
  props: {
    slideUpText: {
      type: String,
      default: ''
    },
    slideDownText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      show: false
    }
  },
  computed: {
    slideText() {
      return this.show ? this.slideUpText : this.slideDownText
    }
  }
}
</script>
<style lang="less" scoped>
.slide {

  .slide-extra {
    max-height: 0;
    overflow: hidden;
    transition: all .6s ease-in;
  }

  .slide-operator {
    text-align: center;
    line-height: 58px;

    .slide-text {
      margin-right: 5px;
      font-size: 22px;
      color: #bbb;
    }

    .arrow-icon {
      color: #bbb;
      transition: all .4s;
    }
  }

  &.on {
    
    .slide-extra {
      max-height: 2000px;
    }

    .arrow-icon {
      transform: rotate(180deg);
    }
  }
}
</style>