import * as orderApi from '@/api/order'
import * as utils from './utils'
import { Dialog } from 'vant'

export default {
  props: {
    carSeriesCode: { // 工程车型code
      type: String,
      default: 'VX1'
    },
    carTypeCode: { // 基础车型code
      type: String,
      default: null
    },
    featureCodeList: { // featureCodeList
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      carSeriesInfo: {}, // 保存当前工程车型
      vehicleInfo: {}, // 保存当前所选车型相关信息
      currentCarType: {}, // 当前选择的基础车型
      carTypeList: [], // 基础车型数据
      outColorList: [], // 外色数据
      interiorList: [], // 内饰数据
      wheelList: [], // 车轮数据
      refrigeratorList: [], // 冰箱数据
      optionalPackageList: [], // 选装包数据
      carconstraintRules: [], // 约束规则
      featureCodes: [], // 所有选配数据
      featureCodeChecked: [] // 保存当前默认已选的选配数据
    }
  },
  computed: {
    channel() {
      return this.$storage.get('channel')
    },
    totalPrice() {
      const featureCodes = this.getCheckedfeatureCodes()
      const sum = (prev, cur) => cur.isPriceIncludes ? prev : (prev + cur.showPrice * 1)
      return featureCodes.reduce(sum, +this.currentCarType.showPrice || 0)
    }
  },
  watch: {
    featureCodeList: {
      immediate: true,
      handler(value) {
        this.featureCodeChecked = value
      }
    },
    totalPrice(value) {
      // this.$emit('on-price-change', value)
      this.$emit('on-price-change', 590000)
    }
  },
  methods: {
    init() {
      this.loading = true
      this.getCarseries()
        .then(this.getCarType)
    },
    // 校验当前用户所选选装包，校验成功则返回车型数据
    validate() {
      return new Promise((resolve, reject) => {
        this.getCarpriceAndCode(this.currentCarType.carTypeCode).then((res) => {
          if (res.success) {
            const getcheckedfeatureCodes = (list) => {
              return list.filter(item => item.checked)
            }
            // 当前所选外色数据
            const outColorList = getcheckedfeatureCodes(this.outColorList)
            // 当前所选内饰数据
            const interiorList = getcheckedfeatureCodes(this.interiorList)
            // 当前所选车轮数据
            const wheelList = getcheckedfeatureCodes(this.wheelList)
            // 当前所选冰箱数据
            const refrigeratorList = getcheckedfeatureCodes(this.refrigeratorList)
            // 当前所选选装包数据
            const optionalPackageList = getcheckedfeatureCodes(this.optionalPackageList)
            // 包含所有已选选装包、外色、内饰、车轮、车轮装饰、冰箱
            const featureCodes = [
              ...outColorList,
              ...interiorList,
              ...wheelList,
              ...refrigeratorList,
              ...optionalPackageList
            ]

            return resolve({
              outColorList,
              interiorList,
              wheelList,
              refrigeratorList,
              optionalPackageList,
              featureCodes,
              carType: this.currentCarType,
              vehicleInfo: this.vehicleInfo,
              carSeriesInfo: this.carSeriesInfo,
              carSeriesCode: this.carSeriesInfo.carSeriesCode
            })
          }
          reject()
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 选择基础车型
    onClickCarType(carType) {
      if (this.currentCarType.carTypeCode === carType.carTypeCode) return
      this.featureCodeChecked = []
      this.triggerCarType(carType)
    },
    // 选择选装包
    onClickFeature(feature, type) {
      // if (type !== 'optionalPackageList') {
      this.triggerFeature(feature, this[type])
      // } else {
      //   this.triggerOptional(feature)
      // }
    },
    // 切换基础车型
    triggerCarType(carType) {
      this.currentCarType = carType
      this.carTypeList.forEach(item => {
        this.$set(item, 'checked', item.carTypeCode === carType.carTypeCode)
      })
      this.loading = true
      this.getFeature(this.currentCarType.carTypeCode).finally(() => {
        this.loading = false
      })
    },
    // 切换个性化选配
    triggerFeature(feature, featureCodes) {
      this.$set(feature, 'checked', !feature.checked)
      featureCodes.forEach(item => {
        if (item.featureId !== feature.featureId) item.checked = false
      })
    },
    // 切换选装包
    triggerOptional(feature) {
      const optionalList = this.optionalPackageList
      const carTypeCode = this.currentCarType.carTypeCode

      const next = () => {
        const target = optionalList.find(item => item.featureCode === feature.featureCode)
        target.checked = !target.checked
        for (const item of optionalList) {
          const hasDepAndChecked = utils.hasDepAndChecked(item, optionalList, carTypeCode)
          // 如果当前项有依赖项，且依赖项没有选择，则disabled
          if (item.featureCode !== feature.featureCode) {
            item.disabled = !item.disabled && !hasDepAndChecked
          }
        }
        this.optionalPackageList = [...optionalList]
      }

      if (feature.disabled) {
        return
      }

      // 判断当前选装包是否是已含配置
      if (feature.isPriceIncludes) {
        Dialog({
          title: '提示',
          message: '当前版本默认包含此套装'
        })
        return
      }

      // 判断是否满足升级配置
      if (!feature.checked && utils.showUpgradeOfGroup(feature, optionalList, carTypeCode)) {
        Dialog.confirm({
          title: '提示',
          message: '勾选此项后，版本将自动升级为豪华版，并获得价格优惠'
        }).then(() => {
          this.handleUpgrade()
        })
        return
      }

      if (feature.featureCode === 'PKG101') {
        const target = optionalList.find(item => item.featureCode === 'PKG301')
        if (target.checked) {
          Dialog.confirm({
            title: '提示',
            message: '取消 "座享套装" 将无法加购 "科技套装"，确定全部取消吗？'
          }).then(() => {
            target.checked = false
            next()
          })
          return
        }
      }

      next()
    },
    handleUpgrade() {
      const carType = this.carTypeList.find(item => item.carTypeCode === 'VX1YYYYY_02')
      const carTypeList = this.carTypeList.map(item => {
        if (item.carTypeCode === carType.carTypeCode) {
          return { ...item, checked: true }
        }
        return { ...item, checked: false }
      })
      this.carTypeList = [...carTypeList]
      this.triggerCarType(carType)
    },
    // 获取工程车型数据
    async getCarseries() {
      const params = {
        channel: this.channel
      }
      const res = await orderApi.getCarseries(params)
      if (res.success) {
        this.carSeriesInfo = res.data.find(item => item.carSeriesCode === this.carSeriesCode) || res.data[0]
      }
    },
    // 获取基础车型数据
    async getCarType() {
      const params = {
        channel: this.channel,
        carSeriesCode: this.carSeriesInfo.carSeriesCode
      }
      const res = await orderApi.getCarType(params)
      if (res.success) {
        this.carTypeList = res.data
        this.triggerCarType(
          this.carTypeCode
            ? this.carTypeList.find(item => item.carTypeCode === this.carTypeCode)
            : res.data[0]
        )
      }
    },
    // 获取选装包数据
    async getFeature(carTypeCode) {
      if (!carTypeCode) return
      const params = {
        channel: this.channel,
        carTypeCode
      }
      this.loading = true
      const res = await orderApi.getCarFeatures(params).finally(() => {
        this.loading = false
      })
      if (res.success) {
        const outColorList = []
        const interiorList = []
        const optionalPackageList = []
        const wheelList = []
        const refrigeratorList = []
        const featureCodeList = this.featureCodeChecked

        res.data.forEach((item) => {
          item.checked = false

          // 处理默认选中，可能从创建订单的提交订单返回上一步过来，也可能从订单详情中进来
          if (featureCodeList && featureCodeList.length && featureCodeList.includes(item.featureCode)) {
            item.checked = true
          }

          // 处理价格已含的逻辑
          item.isPriceIncludes = item.relationShip === 'S' || +item.showPrice === 0

          switch (item.faimlyCode) {
            case 'BAC':
              outColorList.push(item)
              break
            case 'ITRITF':
              interiorList.push(item)
              break
            case 'WWAWWT':
              wheelList.push(item)
              break
            case 'CRT':
              refrigeratorList.push(item)
              break
            case 'PKG1':
            case 'PKG2':
            case 'PKG3':
            case 'PKG':
              optionalPackageList.push(item)
              break
          }
        })

        const carTypeCode = this.currentCarType.carTypeCode
        optionalPackageList.forEach(item => {
          // 如果是价格已含，则当前选装包默认选中
          if (item.isPriceIncludes) item.checked = true
          // 判断此选装包是否需要搭配其他旋转包，如果需要，且其他旋转包没有选择，则当前旋转包不可选择
          if (!item.checked && !utils.hasDepAndChecked(item, optionalPackageList, carTypeCode)) item.disabled = true
          item.deps = utils.getDeps(item, optionalPackageList, carTypeCode)
        })

        this.featureCodes = res.data
        // const replaceName = ['NAMIBU ORANGE', 'ISLAY GREY', 'ONYX BLACK', 'DOLOMITE WHITE PEARL']
        // 外色
        this.outColorList = outColorList.slice(0, 4)
        // 内饰
        this.interiorList = interiorList.map((e, i) => Object.assign(e, { checked: e.checked || i === 1 }))
        // 发动机
        this.optionalPackageList = optionalPackageList
        // 车轮
        this.wheelList = wheelList.map((e, i) => Object.assign(e, { checked: e.checked || i === 1 }))
        // 冰箱
        this.refrigeratorList = refrigeratorList
      }
    },
    // 获取约束规则 - 暂时不用，过于复杂，规则暂时写成配置文件
    async getCarconstraint(carTypeCode) {
      if (!carTypeCode) return
      this.loading = true
      const params = {
        channel: this.channel,
        carTypeCode
      }
      const res = await orderApi.getCarconstraint(params).finally(() => {
        this.loading = false
      })
      if (res.success) {
        this.carconstraintRules = res.data
      }
    },
    // 获取车辆编码及价格
    async getCarpriceAndCode(carTypeCode) {
      if (this.getCheckedfeatureCodes().length !== 4) return;
      const options = {
        channel: this.channel,
        carTypeCode: carTypeCode,
        // featureCodes: this.getCheckedfeatureCodes().map(e => e.featureCode),
        featureCodes: carTypeCode === "VX1XXXXX_01" ? ["BACD", "ITRBITFA", "PKG201", "WWAG"] : ["BACD", "ITRAITFB", "PKG102", "WWAE"],
      }
      return orderApi.getCarprice(options).then(res => {
        if (res.success) {
          this.vehicleInfo = res.data[0]
        } else {
          this.vehicleInfo = {}
          Dialog({
            title: '提示',
            message: res.msg
          })
        }
        return res
      }, err => {
        this.vehicleInfo = {}
        return err
      })
    },
    // 获取当前已选的选装包
    getCheckedfeatureCodes() {
      return this.featureCodes.filter(item => item.checked)
    }
  }
}
