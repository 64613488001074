// 选配相关配置
export default {
  'VX1XXXXX_01': { // 创始版4座
  },
  'VX1XXXXX_02': { // 创始版6座
  },
  'VX1YYYYY_02': { // 旗舰版4座
  },
  'VX1_0003_01': { // 旗舰版6座
  },
  'VX1_0006_01': { // 性能版
    'dep': { // 配置依赖，需要先选择配置项里的选装包，才能选择当前选装包
      'PKG201': ['PKG101'], // 选择旋转包2之前必须选择旋转包1
      'PKG301': ['PKG101'] // 选择旋转包3之前必须选择旋转包1
    },
    'upgradeGroup': { // 配置升级，多个同时选中，则提示是否升级
      'VX1YYYYY_02': ['PKG101', 'PKG201'] // 只要同时选择了选装包1和旋转包2，提示是否升级到豪华版
    }
  }
}
