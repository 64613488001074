import config from './config'

export function showUpgradeOfGroup (feature, optionalList, carTypeCode) {
  const upgradeGroup = config[carTypeCode].upgradeGroup
  if (upgradeGroup) {
    for (const vehicleCode in upgradeGroup) {
      const optionalCodes = upgradeGroup[vehicleCode].slice()
      const index = optionalCodes.indexOf(feature.featureCode)
      if (index > -1) {
        optionalCodes.splice(index, 1)
        if (optionalCodes.every(code => optionalList.find(t => t.featureCode === code).checked)) {
          return true
        }
      }
    }
  }
  return false
}

export function getDeps (feature, optionalList, carTypeCode) {
  const dep = config[carTypeCode].dep
  const featureCode = feature.featureCode
  let res = []
  if (dep && dep[featureCode] && dep[featureCode].length) {
    res = dep[featureCode].map(code => {
      return optionalList.find(t => t.featureCode === code)
    })
  }
  return res
}

export function hasDepAndChecked (feature, optionalList, carTypeCode) {
  const dep = config[carTypeCode].dep
  const featureCode = feature.featureCode
  if (dep && dep[featureCode] && dep[featureCode].length) {
    const deps = dep[featureCode]
    for (const code of deps) {
      const target = optionalList.find(item => item.featureCode === code)
      if (!target.checked) {
        return false
      }
    }
  }
  return true
}
