export function tranfFormDate (valKey, data = "0") {
  try {
    return valKey
  } catch {
    return data;
  }
}
/**
 * 每三位加逗号
 * @param {*} number
 * @returns formated number
 */
export const formateToThousands = (num) => {
  let str
  if (num === null || num === undefined) str = '0'
  else str = num.toString()
  const reg = str.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g
  return str.replace(reg, '$1,')
}